import React from 'react';

const Shop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_shop" data-name="vuesax/outline/shop" transform="translate(-172 -252)">
        <g id="shop">
          <path id="Vector" d="M12.44,12.28H7.04C2.1,12.28,0,10.17,0,5.24V.75A.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75V5.24c0,4.09,1.45,5.54,5.54,5.54h5.39c4.09,0,5.54-1.45,5.54-5.54V.75a.75.75,0,0,1,1.5,0V5.24C19.48,10.17,17.37,12.28,12.44,12.28Z" transform="translate(174.26 262.47)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M3.761,11.5A3.7,3.7,0,0,1,.021,7.36L.691.68A.758.758,0,0,1,1.441,0h4.67a.75.75,0,0,1,.75.68l.67,6.68a3.74,3.74,0,0,1-.93,2.92A3.832,3.832,0,0,1,3.761,11.5Zm-1.65-10-.6,6.01a2.236,2.236,0,0,0,.55,1.76,2.4,2.4,0,0,0,3.4,0,2.259,2.259,0,0,0,.55-1.76l-.6-6.01Z" transform="translate(180.239 253.25)" fill="CurrentColor" />
          <path id="Vector-3" data-name="Vector" d="M4.753,11.5A4.163,4.163,0,0,1,.7,7.84L0,.83A.772.772,0,0,1,.193.25.735.735,0,0,1,.753,0H3.8c2.94,0,4.31,1.23,4.72,4.25L8.8,7.03a4.011,4.011,0,0,1-4.05,4.47Zm-3.17-10L2.2,7.69A2.658,2.658,0,0,0,4.753,10a2.5,2.5,0,0,0,2.56-2.82l-.28-2.75C6.723,2.17,5.993,1.5,3.8,1.5Z" transform="translate(185.557 253.25)" fill="CurrentColor" />
          <path id="Vector-4" data-name="Vector" d="M4.073,11.5A4.011,4.011,0,0,1,.023,7.03l.27-2.75C.713,1.23,2.083,0,5.023,0h3.05a.767.767,0,0,1,.56.25.734.734,0,0,1,.19.58l-.7,7.01A4.163,4.163,0,0,1,4.073,11.5Zm.95-10c-2.19,0-2.92.66-3.24,2.95l-.27,2.73A2.5,2.5,0,0,0,4.073,10a2.65,2.65,0,0,0,2.55-2.31l.62-6.19Z" transform="translate(173.567 253.25)" fill="CurrentColor" />
          <path id="Vector-5" data-name="Vector" d="M5.75,6.5h-5A.755.755,0,0,1,0,5.75V3.25A2.928,2.928,0,0,1,3.25,0,2.928,2.928,0,0,1,6.5,3.25v2.5A.755.755,0,0,1,5.75,6.5ZM1.5,5H5V3.25C5,1.99,4.51,1.5,3.25,1.5S1.5,1.99,1.5,3.25Z" transform="translate(180.75 268.25)" fill="CurrentColor" />
          <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 252)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};

export default Shop;
