import React from 'react';

const Gallery = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_gallery-edit" data-name="vuesax/outline/gallery-edit" transform="translate(-172 -316)">
        <g id="gallery-edit">
          <path id="Vector" d="M2.75,5.5A2.75,2.75,0,1,1,5.5,2.75,2.748,2.748,0,0,1,2.75,5.5Zm0-4A1.25,1.25,0,1,0,4,2.75,1.25,1.25,0,0,0,2.75,1.5Z" transform="translate(178.25 321.25)" fill="currentColor" />
          <path id="Vector-2" data-name="Vector" d="M13.75,21.5h-6C2.32,21.5,0,19.18,0,13.75v-6C0,2.32,2.32,0,7.75,0h4a.755.755,0,0,1,.75.75.755.755,0,0,1-.75.75h-4C3.14,1.5,1.5,3.14,1.5,7.75v6C1.5,18.36,3.14,20,7.75,20h6C18.36,20,20,18.36,20,13.75v-5a.75.75,0,0,1,1.5,0v5C21.5,19.18,19.18,21.5,13.75,21.5Z" transform="translate(173.25 317.25)" fill="currentColor" />
          <path id="Vector-3" data-name="Vector" d="M1.417,8.505A1.38,1.38,0,0,1,.407,8.1a1.448,1.448,0,0,1-.39-1.24l.2-1.39a1.952,1.952,0,0,1,.52-1.03L4.367.805a2.134,2.134,0,0,1,3.33,0,2.3,2.3,0,0,1,.8,1.85,2.411,2.411,0,0,1-.8,1.48l-3.63,3.63a2.107,2.107,0,0,1-1.03.52l-1.39.2A.934.934,0,0,1,1.417,8.505Zm4-6.63-3.63,3.63a.557.557,0,0,0-.09.19L1.507,7l1.31-.18a.582.582,0,0,0,.19-.1l3.63-3.63A1.07,1.07,0,0,0,7,2.515c.02-.21-.19-.47-.36-.64C6.117,1.355,5.877,1.415,5.417,1.875Z" transform="translate(186.253 317.245)" fill="currentColor" />
          <path id="Vector-4" data-name="Vector" d="M3.017,3.767a.645.645,0,0,1-.2-.03A4.077,4.077,0,0,1,.027.947a.747.747,0,0,1,1.44-.4,2.574,2.574,0,0,0,1.76,1.76.749.749,0,0,1,.52.92A.791.791,0,0,1,3.017,3.767Z" transform="translate(189.883 318.363)" fill="currentColor" />
          <path id="Vector-5" data-name="Vector" d="M.747,8.453a.75.75,0,0,1-.42-1.37l4.93-3.31a3,3,0,0,1,3.55.19l.33.29a1.473,1.473,0,0,0,1.84,0l4.16-3.57a2.986,2.986,0,0,1,3.8,0l1.63,1.4a.752.752,0,0,1-.98,1.14l-1.63-1.4a1.473,1.473,0,0,0-1.84,0l-4.16,3.57a2.986,2.986,0,0,1-3.8,0l-.33-.29a1.487,1.487,0,0,0-1.73-.08l-4.92,3.31A.876.876,0,0,1,.747,8.453Z" transform="translate(173.923 327.247)" fill="currentColor" />
          <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 316)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>


  );
};

export default Gallery;
