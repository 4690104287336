import React from 'react';

const Easy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_bag-tick-2" data-name="vuesax/outline/bag-tick-2" transform="translate(-428 -188)">
        <g id="bag-tick-2">
          <path id="Vector" d="M4.75,9.5A4.75,4.75,0,1,1,9.5,4.75,4.754,4.754,0,0,1,4.75,9.5Zm0-8A3.25,3.25,0,1,0,8,4.75,3.256,3.256,0,0,0,4.75,1.5Z" transform="translate(435.25 198.25)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M1.747,3.278a1.21,1.21,0,0,1-.88-.37l-.65-.65A.75.75,0,0,1,1.278,1.2l.48.48L3.368.2a.75.75,0,0,1,1.02,1.1l-1.78,1.64A1.27,1.27,0,0,1,1.747,3.278Z" transform="translate(437.693 201.262)" fill="CurrentColor" />
          <path id="Vector-3" data-name="Vector" d="M12.5,15.5h-6c-4.62,0-5.48-2.15-5.7-4.24L.054,5.27a4.746,4.746,0,0,1,.9-3.79C1.854.48,3.344,0,5.5,0h8c2.17,0,3.66.49,4.55,1.48a4.756,4.756,0,0,1,.9,3.77l-.75,6.01C17.984,13.35,17.124,15.5,12.5,15.5Zm-7-14c-1.69,0-2.85.33-3.44.99a3.236,3.236,0,0,0-.52,2.61l.75,5.99c.17,1.6.61,2.92,4.21,2.92h6c3.6,0,4.04-1.31,4.21-2.9l.75-6.01a3.242,3.242,0,0,0-.52-2.6c-.59-.67-1.75-1-3.44-1Z" transform="translate(430.496 195.25)" fill="CurrentColor" />
          <path id="Vector-4" data-name="Vector" d="M9.75,7.384A.755.755,0,0,1,9,6.634V5.254a3.78,3.78,0,0,0-1.23-2.78,3.723,3.723,0,0,0-2.89-.95A4.043,4.043,0,0,0,1.5,5.454v.97a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,6.424v-.98A5.544,5.544,0,0,1,4.74.024a5.189,5.189,0,0,1,4.04,1.34,5.27,5.27,0,0,1,1.72,3.89v1.38A.755.755,0,0,1,9.75,7.384Z" transform="translate(434.75 189.246)" fill="CurrentColor" />
          <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(428 188)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>

  );
};

export default Easy;
