import React from 'react';

const People = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_user-tag" data-name="vuesax/outline/user-tag" transform="translate(-556 -188)">
        <g id="user-tag">
          <path id="Vector" d="M9.75,21.49a2.749,2.749,0,0,1-1.94-.8L6.1,19a2.256,2.256,0,0,0-1.59-.65H3.75A3.739,3.739,0,0,1,0,14.63V3.72A3.739,3.739,0,0,1,3.75,0h12A3.739,3.739,0,0,1,19.5,3.72V14.63a3.739,3.739,0,0,1-3.75,3.72h-.76A2.3,2.3,0,0,0,13.4,19l-1.71,1.69A2.749,2.749,0,0,1,9.75,21.49Zm-6-20A2.241,2.241,0,0,0,1.5,3.71V14.62a2.234,2.234,0,0,0,2.25,2.22h.76a3.8,3.8,0,0,1,2.65,1.09l1.71,1.69a1.268,1.268,0,0,0,1.77,0l1.71-1.69A3.8,3.8,0,0,1,15,16.84h.75A2.241,2.241,0,0,0,18,14.62V3.71a2.234,2.234,0,0,0-2.25-2.22Z" transform="translate(558.25 189.26)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M3.08,6.16A3.08,3.08,0,1,1,6.16,3.08,3.081,3.081,0,0,1,3.08,6.16Zm0-4.66A1.58,1.58,0,1,0,4.66,3.08,1.583,1.583,0,0,0,3.08,1.5Z" transform="translate(564.92 192.59)" fill="CurrentColor" />
          <path id="Vector-3" data-name="Vector" d="M8.75,4.76A.755.755,0,0,1,8,4.01C8,2.63,6.54,1.5,4.75,1.5S1.5,2.63,1.5,4.01a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,4.01C0,1.8,2.13,0,4.75,0S9.5,1.8,9.5,4.01A.755.755,0,0,1,8.75,4.76Z" transform="translate(563.25 199.65)" fill="CurrentColor" />
          <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(556 188)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>


  );
};

export default People;
